.sidebar {
  background-color: rgba(0, 0, 0, 0.8);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 100vh;
  padding-top: 3rem;
}

.sidebar .link {
  color: white;
  text-decoration: none;
  font-weight: 300;
  font-size: 1.6rem;
  padding: 1rem 2rem;
}

.sidebar .link:hover {
  background-color: rgba(255, 255, 255, 0.459);
}

.locked-link {
  pointer-events: none;
}
