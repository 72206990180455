.info-tab {
    display: flex;
    justify-content: space-between;

}

.project-info {
    margin-top: 2rem;
    padding: 1rem;
    position: relative;

    .project-edit-btn {
        position: absolute;
        top: 1rem;
        right: 1rem;
        cursor: pointer;
    }

    h1 {
        font-weight: 500;
        font-size: 1.8rem;
        margin-bottom: 2rem;
    }

    .info-fields {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 3rem;
        row-gap: 2rem;

        .info-pair {
            display: flex;
            flex-direction: column;
            gap: .2rem;
        }

        .info-field {
            display: flex;

            p {
                margin: 0;
            }


        }

        .info-label {
            width: 8ch;
        }
    }

    .notes {
        margin-top: 3rem;

        .notes-headline {
            text-decoration: underline;
        }
    }
}

.project-info-edit-window {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.8);
    top: 50%;
    right: 50%;
    transform: translateY(-50%) translateX(50%);
    z-index: 90;
    //backdrop-filter: blur(15px);

    padding: 3rem;

    border-radius: 1rem;

    .basic-form {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 3rem;
        row-gap: .5rem;
    }

    .form-element {

        p {
            margin-bottom: .2rem;
            font-weight: 300;
        }

        input {
            background-color: rgba(255, 255, 255, 0.3);
            border: none;
            border-radius: .2rem;
            padding: .2rem .4rem;
            color: rgba(255, 255, 255, 0.7);
            font-weight: 300;
            font-family: "Urbanist";
            font-size: 1rem;
        }

    }


    .close-edit-window-btn {
        cursor: pointer;
        position: absolute;
        top: 1rem;
        right: 1rem;
    }

    .input-submit {
        margin-top: 2rem;
    }



}