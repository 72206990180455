.step {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  position: relative;
  gap: 1rem;
  padding: .2rem .2rem;
  max-width: 300px;
}

.step div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: .5rem;
}

.step:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.step:hover img {
  opacity: .6;
}

.step .step-toggle-checked {
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
  cursor: pointer;
}

.step .delete-step-btn {
  cursor: pointer;
  -webkit-transform: scale(0.6);
          transform: scale(0.6);
  opacity: 0;
  -webkit-transition: opacity 250ms;
  transition: opacity 250ms;
}

.step p {
  margin: .4rem 0;
}

.finished-true .step-text {
  text-decoration: line-through;
}
