.dashboard-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 25vh 25vh;
    gap: 3rem;

    .tasks-no-owner {
        grid-row-start: 1;
        grid-row-end: 3;
        grid-column-start: 3;
        //background-color: chartreuse;
    }
}


