.project-finances {
    padding: 2rem;
    margin-top: 2rem;
    width: fit-content;
    display: flex;
    flex-direction: column;
    gap: .5rem;

    .est-revenue {
        p {
            &:nth-of-type(1) {
                opacity: .7;
                font-weight: 300;
                margin-bottom: .2rem;
            }
            &:nth-of-type(2) {
                margin-top: 0;
                font-weight: 300;
            }
        }
    }

    .est-profit {
        p {
            &:nth-of-type(1) {
                opacity: .7;
                font-weight: 300;
                margin-bottom: .2rem;
            }
            &:nth-of-type(2) {
                margin-top: 0;
                font-weight: 300;
                font-size: 1.4rem;
            }
        }
    }
}