.task {
  position: relative;
  gap: .5rem;
  background-color: rgba(0, 0, 0, 0.15);
  margin: .3rem;
  padding: .5rem 1rem;
  border-radius: .5rem;
}

.task .task-base {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.task:hover .task-edit-btn {
  opacity: .8;
  pointer-events: auto;
}

.task-toggle-checked {
  cursor: pointer;
}

.task-info {
  margin-left: 1rem;
}

.task-info .task-text {
  font-size: 1.4rem;
  margin: .3rem;
  font-weight: 400;
}

.task-info .task-project {
  opacity: .8;
  margin: .3rem;
  font-weight: 200;
}

.task-edit-btn {
  position: absolute;
  top: 50%;
  right: 1rem;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  opacity: 0;
  pointer-events: none;
  -webkit-transition: opacity 250ms;
  transition: opacity 250ms;
  cursor: pointer;
}

.edit-task-window {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.8);
  top: 50%;
  right: 50%;
  -webkit-transform: translateY(-50%) translateX(50%);
          transform: translateY(-50%) translateX(50%);
  z-index: 90;
  padding: 3rem;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  gap: 2rem;
  border-radius: 1rem;
}

.edit-task-window .form-element p {
  margin-bottom: .2rem;
  font-weight: 300;
}

.edit-task-window .form-element input {
  background-color: rgba(255, 255, 255, 0.3);
  border: none;
  border-radius: .2rem;
  padding: .2rem .4rem;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 300;
  font-family: "Urbanist";
  font-size: 1rem;
}

.edit-task-window .input-submit {
  display: none;
}

.close-edit-window-btn {
  cursor: pointer;
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.delete-task-btn {
  cursor: pointer;
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}

.finished-true .task-text {
  text-decoration: line-through;
  font-weight: 300;
}

.steps-list {
  margin-left: 1rem;
}

.step-input {
  background-color: rgba(0, 0, 0, 0);
  border: none;
  color: white;
  margin-top: .4rem;
  padding: .1rem .5rem;
}
