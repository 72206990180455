.client-info {
  margin-top: 2rem;
  padding: 1rem;
}

.client-info h1 {
  font-weight: 500;
  font-size: 1.8rem;
  margin-bottom: 2rem;
}

.client-info .info-fields {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: .5rem;
}

.client-info .info-fields .info-field {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.client-info .info-fields .info-field p {
  margin: 0;
}

.client-info .info-fields .info-label {
  width: 16ch;
}

.client-info .notes {
  margin-top: 3rem;
}

.client-info .notes .notes-headline {
  text-decoration: underline;
}

.client-edit-window {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.8);
  top: 50%;
  right: 50%;
  -webkit-transform: translateY(-50%) translateX(50%);
          transform: translateY(-50%) translateX(50%);
  z-index: 90;
  padding: 3rem;
  border-radius: 1rem;
}

.client-edit-window .basic-form {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  -webkit-column-gap: 3rem;
          column-gap: 3rem;
  row-gap: .5rem;
}

.client-edit-window .form-element p {
  margin-bottom: .2rem;
  font-weight: 300;
}

.client-edit-window .form-element input {
  background-color: rgba(255, 255, 255, 0.3);
  border: none;
  border-radius: .2rem;
  padding: .2rem .4rem;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 300;
  font-family: "Urbanist";
  font-size: 1rem;
}

.client-edit-window .close-edit-window-btn {
  cursor: pointer;
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.client-edit-window .input-submit {
  margin-top: 2rem;
}
