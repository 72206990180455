.controls {
    display: flex;
    justify-content: space-between;
}

.project-buttons {
    display: flex;
    gap: .5rem;
}

.not-highlighted {
    opacity: .4;
    font-weight: 400;
}

.project-tabs {
    display: flex;
    gap: .5rem;    
}

.add-project-input {
    padding: .5rem 1rem;
    font-family: Urbanist;
    font-size: 1rem;
    background-color: rgba(0, 0, 0, 0.7);
    font-family: "Urbanist";
    border: none;
    
    &::placeholder {
        color: white;
        opacity: .5;
    }
}