.client-div {
    display: flex;
    justify-content: space-between;
    padding: 0 1rem;
    
    

    img {
        opacity: 0;
        //pointer-events: none;

        &:nth-of-type(2) {
            opacity: 2;
        }
    }
    

    p {
        &:nth-of-type(1) {
            width: 20ch;
        }

        &:nth-of-type(2) {
            width: 15ch;
        }

        &:nth-of-type(3) {
            width: 15ch;
        }

        &:nth-of-type(4) {
            width: 20ch;
        }

        &:nth-of-type(5) {
            width: 20ch;
        }
    }


}

.client-div-full {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: .2rem;
    padding: .5rem 1rem;
    margin: .5rem 0;

    &:hover {
        background-color: rgba(0, 0, 0, 0.2);
        cursor: pointer;
    }

    img {
        pointer-events: all;        
        opacity: 1;

        &:hover {
            opacity: .5;
        }


    }
}

.client-btn {
    width: fit-content;
    margin: 1rem 0;
}