.controls {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.project-buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: .5rem;
}

.not-highlighted {
  opacity: .4;
  font-weight: 400;
}

.project-tabs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: .5rem;
}

.add-project-input {
  padding: .5rem 1rem;
  font-family: Urbanist;
  font-size: 1rem;
  background-color: rgba(0, 0, 0, 0.7);
  font-family: "Urbanist";
  border: none;
}

.add-project-input::-webkit-input-placeholder {
  color: white;
  opacity: .5;
}

.add-project-input:-ms-input-placeholder {
  color: white;
  opacity: .5;
}

.add-project-input::-ms-input-placeholder {
  color: white;
  opacity: .5;
}

.add-project-input::placeholder {
  color: white;
  opacity: .5;
}
