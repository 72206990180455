@import url("https://fonts.googleapis.com/css2?family=Urbanist:wght@100;200;300;400;500&display=swap");
:root {
  margin: 0;
  padding: 0;
  font-size: 18px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  --bg-main: #606060;
  --bg-dark: #272727;
}

body {
  background-color: var(--bg-main);
  color: white;
  font-family: 'Urbanist', sans-serif;
  word-spacing: 5px;
}

body .shadow {
  -webkit-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
          box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}

.widget {
  background-color: rgba(73, 46, 46, 0.25);
  border: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
          box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
  overflow-y: hidden;
}

.button-tab {
  background-color: rgba(0, 0, 0, 0.7);
  padding: .5rem 1rem;
  font-weight: 500;
  margin: 0;
  cursor: pointer;
}

.button-tab:hover {
  background-color: rgba(255, 255, 255, 0.7);
  color: black;
}

.active {
  background-color: white;
  color: black;
}

.App {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-right: 2rem;
  gap: 3rem;
}

.App .app-content {
  margin-top: 2rem;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

.add-btn:hover {
  opacity: .1;
  cursor: pointer;
}
