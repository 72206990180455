.widget-head {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .headline {
        font-size: 1.4rem;
        text-align: center;
    }

    form {
        margin-left: 1rem;
        margin-bottom: .5rem;
    }
}