.sidebar {
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: column;
    //position: fixed;
    //top: 0;
    //left: 0;
    height: 100vh;
    padding-top: 3rem;

    
    

    .link {
        color: white;
        text-decoration: none;
        font-weight: 300;
        font-size: 1.6rem;
        padding: 1rem 2rem;

        &:hover {
            background-color: rgba(255, 255, 255, 0.459);
        }
    }


}    

.locked-link {
        pointer-events: none;

        
        
    }