.task-completed-widget {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
}

.task-completed-widget .big-number {
  font-size: 4rem;
  margin: .4rem;
}

.task-completed-widget .headline {
  font-size: .8rem;
}

.task-completed-widget .subhead {
  font-size: .8rem;
  opacity: .5;
}
