.widget-head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.widget-head .headline {
  font-size: 1.4rem;
  text-align: center;
}

.widget-head form {
  margin-left: 1rem;
  margin-bottom: .5rem;
}
