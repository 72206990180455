.client-div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0 1rem;
}

.client-div img {
  opacity: 0;
}

.client-div img:nth-of-type(2) {
  opacity: 2;
}

.client-div p:nth-of-type(1) {
  width: 20ch;
}

.client-div p:nth-of-type(2) {
  width: 15ch;
}

.client-div p:nth-of-type(3) {
  width: 15ch;
}

.client-div p:nth-of-type(4) {
  width: 20ch;
}

.client-div p:nth-of-type(5) {
  width: 20ch;
}

.client-div-full {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: .2rem;
  padding: .5rem 1rem;
  margin: .5rem 0;
}

.client-div-full:hover {
  background-color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.client-div-full img {
  pointer-events: all;
  opacity: 1;
}

.client-div-full img:hover {
  opacity: .5;
}

.client-btn {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 1rem 0;
}
