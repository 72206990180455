.step {
    display: flex;
    justify-content: space-between;
    position: relative;
    gap: 1rem;
    padding: .2rem .2rem;
    max-width: 300px;

    div {
        display: flex;
        gap: .5rem;
    }
    

    &:hover {
        background-color: rgba(255, 255, 255, 0.2);

        img {
            opacity: .6;
        }
    }

    .step-toggle-checked {
        transform: scale(0.8);
        cursor: pointer;
    }

    .delete-step-btn {
        cursor: pointer;
        
        transform: scale(0.6);
        
        opacity: 0;
        transition: opacity 250ms;

        

    }

    p {
        margin: .4rem 0;
    }
}

.finished-true {
    .step-text {
        text-decoration: line-through;
    }
}