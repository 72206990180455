.task-columns {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 45% 45%;
      grid-template-columns: 45% 45%;
  gap: 10%;
}

.task-columns .headline {
  margin-top: 1rem;
}

.add-task-form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  gap: 1rem;
  background-color: rgba(0, 0, 0, 0.13);
  padding: .1rem .6rem;
  border-radius: .4rem;
  margin-top: 2rem;
}

.add-task-form div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 1rem;
}

.task-input {
  background-color: rgba(0, 0, 0, 0);
  border: none;
  color: white;
  opacity: 1;
  margin-top: .4rem;
  padding: .4rem .5rem;
}

.task-input::-webkit-input-placeholder {
  color: white;
  opacity: .8;
  font-family: "Urbanist";
}

.task-input:-ms-input-placeholder {
  color: white;
  opacity: .8;
  font-family: "Urbanist";
}

.task-input::-ms-input-placeholder {
  color: white;
  opacity: .8;
  font-family: "Urbanist";
}

.task-input::placeholder {
  color: white;
  opacity: .8;
  font-family: "Urbanist";
}
