.task-completed-widget {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    .big-number {
        font-size: 4rem;
        margin: .4rem;
    }

    .headline {
        font-size: .8rem;

    }

    .subhead {
        font-size: .8rem;
        opacity: .5;
    }
}