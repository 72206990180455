.task-columns {
    display: grid;
    grid-template-columns: 45% 45%;
    gap: 10%;

    .headline {
        
        margin-top: 1rem;
    }



    
}

.add-task-form {
    display: flex;
    //justify-content: space-between;
    align-items: center;
    width: fit-content;
    gap: 1rem;
    background-color: rgba(0, 0, 0, 0.13);
    padding: .1rem .6rem;
    border-radius: .4rem;
    margin-top: 2rem;

    div {
        display: flex;
        align-items: center;
        gap: 1rem;
    }
    
}

.task-input {
    background-color: rgba(0, 0, 0, 0);
    border: none;
    color: white;
    opacity: 1;
    margin-top: .4rem;
    padding: .4rem .5rem;

    &::placeholder {
        color: white;
        opacity: .8;
        font-family: "Urbanist";
    }
}