.widget-container {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: 25vh 25vh;
      grid-template-rows: 25vh 25vh;
  gap: 3rem;
}

.widget-container .tasks-no-owner {
  -ms-grid-row: 1;
      grid-row-start: 1;
  grid-row-end: 3;
  -ms-grid-column: 3;
      grid-column-start: 3;
}
