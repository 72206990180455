.project-finances {
  padding: 2rem;
  margin-top: 2rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: .5rem;
}

.project-finances .est-revenue p:nth-of-type(1) {
  opacity: .7;
  font-weight: 300;
  margin-bottom: .2rem;
}

.project-finances .est-revenue p:nth-of-type(2) {
  margin-top: 0;
  font-weight: 300;
}

.project-finances .est-profit p:nth-of-type(1) {
  opacity: .7;
  font-weight: 300;
  margin-bottom: .2rem;
}

.project-finances .est-profit p:nth-of-type(2) {
  margin-top: 0;
  font-weight: 300;
  font-size: 1.4rem;
}
